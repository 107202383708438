var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-12", attrs: { tile: "" } },
    [
      _c("v-card-title", [
        _vm._v("Details Supply Tipology " + _vm._s(_vm.Model.Name)),
      ]),
      _c("v-divider"),
      _c(
        "v-form",
        [
          _vm.isLoading
            ? _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: { "max-width": "600", type: "card", loading: "" },
              })
            : _vm._e(),
          _vm.hasTipologiaFornitura
            ? _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "2" } },
                        [
                          _c("pga-text-field", {
                            attrs: { label: "Code", readonly: "" },
                            model: {
                              value: _vm.Model.Code,
                              callback: function ($$v) {
                                _vm.$set(_vm.Model, "Code", $$v)
                              },
                              expression: "Model.Code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("pga-text-field", {
                            attrs: { label: "Title", readonly: "" },
                            model: {
                              value: _vm.Model.Name,
                              callback: function ($$v) {
                                _vm.$set(_vm.Model, "Name", $$v)
                              },
                              expression: "Model.Name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("pga-entity-status", {
                            attrs: { title: "Status" },
                            model: {
                              value: _vm.Model.Status,
                              callback: function ($$v) {
                                _vm.$set(_vm.Model, "Status", $$v)
                              },
                              expression: "Model.Status",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("pga-select", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            attrs: {
                              "item-text": "FullName",
                              items: _vm.Model.Users,
                              label: "Created By",
                              readonly: "",
                            },
                            model: {
                              value: _vm.Model.Users,
                              callback: function ($$v) {
                                _vm.$set(_vm.Model, "Users", $$v)
                              },
                              expression: "Model.Users",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }